/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
*:not(.mat-icon) {
  font-family: "Poppins", sans-serif !important;
}

html, body {
  height: 100%;
}

body {
  min-height: 100vh;
  background-color: #e5e5e5 !important;
}

.app-header {
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
}

.main-wrapper {
  height: 100%;
}

.positronx {
  text-decoration: none;
  color: #ffffff;
}

.box {
  position: relative;
  top: 0;
  opacity: 1;
  float: left;
  padding: 60px 50px 40px 50px;
  width: 100%;
  background: #fff;
  border-radius: 10px;
  transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  z-index: 5;
  max-width: 330px;
}

.box.back {
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  top: -20px;
  opacity: 0.8;
  z-index: -1;
}

.box:before {
  content: "";
  width: 100%;
  height: 30px;
  border-radius: 10px;
  position: absolute;
  top: -10px;
  background: rgba(255, 255, 255, 0.6);
  left: 0;
  transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -ms-transform: scale(0.95);
  z-index: -1;
}

.main-wrapper .example-form {
  min-width: 100%;
  max-width: 300px;
  width: 100%;
}

.main-wrapper .example-full-width, .main-wrapper .btn-block {
  width: 100%;
}

.main-wrapper mat-card-header {
  text-align: center;
  width: 100%;
  display: block;
  font-weight: 700;
}

.main-wrapper mat-card-header mat-card-title {
  font-size: 30px;
  margin: 0;
}

.main-wrapper .mat-card {
  padding: 45px 70px 55px;
}

.main-wrapper .mat-stroked-button {
  border: 1px solid currentColor;
  line-height: 54px;
  background: #fff7fa;
}

.main-wrapper .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8375em 0;
}

.file-drop-zone {
  border: 2px dotted #e9e9e9;
  border-radius: 20px;
  height: 90px;
  margin: auto;
  text-align: center;
  width: 100%;
}

.file-drop-zone-content {
  align-items: center;
  color: #607d8b;
  display: flex;
  height: 100px;
  justify-content: center;
  font-size: 0.8rem;
  width: 105%;
  padding: 20px;
}

.group-file-drop-zone {
  border: 2px dotted #e9e9e9;
  border-radius: 10px;
  height: 85%;
  margin: auto;
  text-align: center;
  width: 98%;
  vertical-align: middle;
  /* margin: auto; */
  margin-top: 4.5%;
}

.group-file-drop-zone-content {
  align-items: center;
  color: #607d8b;
  display: flex;
  height: 100%;
  justify-content: center;
  font-size: 0.8rem;
  width: 105%;
  padding: 20px;
  vertical-align: middle;
  margin: auto;
  /* display: block; */
  /* margin-top: 40%; */
}

/*///// custome css /////*/

/* form field style */

.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 1.5px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #e8e8ef;
}

.mat-select-arrow {
  color: #c7cbd0;
}

label {
  display: block !important;
  color: #828282;
  font-size: 12.5px;
  font-weight: 400;
}

.btn-primary-custome {
  border: none;
  background-color: #3e2093;
  color: #fff;
  font-size: 15px;
  border-radius: 8px;
  padding: 7px 10px;
  min-width: 130px;
  min-height: 45px;
}

.btn-primary-custome:disabled {
  background-color: #3e209370;
}

.btn-secondary-custome {
  border: none;
  background-color: #EBEBEB;
  color: #AAAAAA;
  font-size: 15px;
  border-radius: 8px;
  padding: 7px 10px;
  min-width: 130px;
  min-height: 45px;
}

.action-btn {
  bottom: 0;
  right: 0;
}

/* 
.btn-secondary-custome:hover {
  background-color: #3e2093;
  color: #fff;
}  */

.title {
  background-color: #3e209317;
  padding: 15px;
  border-radius: 5px;
  color: #673ab7;
}

.custom-input {
  width: 100%;
  display: block;
  background-color: #EBEBEB;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
  outline-color: #5C329E;
  /* box-shadow: 0 2px 6px rgb(0, 0, 0, 16%) !important */
}

.custom-input:disabled {
  color: #AAAAAA;
  cursor: not-allowed;
}

/* .custom-input:focus-visible {
  background-color: #F1E8FF;
} */

.selection-btn {
  background-color: #EBEBEB;
  border: none !important;
  border-radius: 8px;
  display: block;
}

/* table style */

.mat-elevation-z8 {
  box-shadow: 0 3px 13px rgb(0 0 0 / 7%);
  background-color: #fff;
  border-radius: 8px;
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: #e8e8ef;
}

.mat-header-cell {
  color: #673ab7;
}

/* th.mat-header-cell, td.mat-cell {
  text-align: center !important;
} */

.table thead th {
  font-weight: 500;
  font-size: 12px;
  color: #673ab7 !important;
}

.table th, .table td {
  font-size: 14px;
}

.table .thead-light th {
  background-color: #673ab714 !important;
  border-color: #673ab761 !important;
}

.table th, .table td {
  border-top: 2px solid #dee2e6 !important;
}

.fw-semibold {
  font-weight: 500;
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1300px !important;
  }
}